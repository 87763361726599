@import "../library.scss";

#valuations-content {
  margin: auto;
  top: 65px;
  position: relative;
  background-color: white;

  .actions-container {
    position: absolute;
    right: 20px;
    top: 40px;
    button:first-child {
      margin-right: 10px;
    }
  }
}

#interested-in-selling {
  h2 {
    @media (max-width: $tablet-breakpoint) {
      padding-top: 40px;
    }
  }
  .options-container {
    display: flex;
    justify-content: center;
    font-weight: 400;

    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }

    h4 {
      font-size: 20px;
      margin: 10px 0px;
      font-weight: 400;
    }
  }
  .option {
    display: flex;
    flex-direction: column;
    min-width: calc(40% - 10px);
    margin: 10px;
    position: relative;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 15px 30px;
    background-color: white;

    @media (max-width: $tablet-breakpoint) {
      margin: 10px 20px;
      padding: 15px 20px;
    }

    .proceeds-border {
      content: "";
      height: 1px;
      background-color: $light-grey-2;
      margin: 20px 0px 10px 0px;
    }

    .option-bullet {
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;
    }

    .option-bullet.savings {
      span:nth-child(2) {
        color: $grass-green;
        font-weight: 400;
      }
    }
    .option-bullet.proceeds {
      // border-bottom: 2px solid $light-grey-1;

      display: flex;
      align-items: center;
      h5 {
        margin: 0px;
        font-size: 16px;
      }
      span {
        font-weight: 600;
      }
    }

    .schedule-consultation {
      display: flex;
      flex-direction: column;
      p {
        font-size: 20px;
      }
      .consultant {
        display: flex;
        position: relative;
        .profile-img {
          max-width: 75px;
          max-height: 75px;
          border-radius: 100px;
        }
        .info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          img {
            margin-top: 10px;
            max-width: 75px;
          }
          span {
            margin-bottom: 5px;
          }
          .name {
            font-size: 18px;
            font-weight: 400;
          }
          .meta {
            font-size: 14px;
          }
        }
        button {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
  .option.felix-option {
    border-top: 5px solid $light-blue;
    .logo-mark {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 40px;
    }
  }
  .option.traditional-option {
    border-top: 5px solid $dark-blue;
  }
  .disclaimer-container-mobile,
  .disclaimer-container-desktop {
    margin: auto;
    font-size: 14px;
    font-weight: 300 !important;
    height: 25px;
  }

  .disclaimer-container-mobile {
    @media (min-width: $tablet-breakpoint) {
      display: none;
    }
    width: 90%;
  }
  .disclaimer-container-desktop {
    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
    width: 80%;
  }
}

#compare-valuations {
  margin-top: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 0px;
  }
  p {
    margin-top: 0px;
    text-align: center;
    max-width: 650px;
    margin-bottom: 30px;
    line-height: 25px;
    padding: 0px 20px;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
}

#compare-services {
  margin-bottom: 75px;
  @media (max-width: $tablet-breakpoint) {
    margin-bottom: 45px;
  }
  h2 {
    @media (max-width: $tablet-breakpoint) {
      padding: 20px 0px 0px 0px;
    }
  }
  @media (max-width: $tablet-breakpoint) {
    padding: 30px;
  }
  .table {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;

    .logo {
      max-width: 150px;
      @media (max-width: $tablet-breakpoint) {
        max-width: 125px;
      }
    }

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $grey-1;
      padding: 20px 0px;
      align-items: center;
      @media (max-width: $tablet-breakpoint) {
        padding: 5px 0px 15px 0px;
      }
    }

    .icon {
      width: 24px;
    }
    .col-2 {
      flex: 2;
      display: flex;
      font-weight: 400;
      font-size: 18px;
      @media (max-width: $tablet-breakpoint) {
        justify-content: center;
        width: 100%;
        flex: none;
        margin: 15px 0px;
      }
    }
    .col-1 {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .col-1.header {
      font-size: 14px;
      font-weight: 600;
      color: $dark-blue;
      letter-spacing: 1px;
      @media (max-width: $tablet-breakpoint) {
        font-size: 12px;
      }
    }
  }
}
