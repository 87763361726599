@import "../../../library.scss";

#modal-body {
  position: absolute;
  width: calc(100% - 40px);
  max-width: 794px;
  max-height: 530px;
  height: 100%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}
