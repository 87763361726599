@import "../library.scss";

.footer-wrapper {
  .top {
    display: flex;

    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: fit-content;

      a {
        margin: 0 17px;
      }
    }

    .left {
      display: flex;
      width: calc(72% - 240px);
      padding: 80px 120px 40px;
      background-color: $navy-color;
      justify-content: space-between;

      .column-wrapper {
        display: flex;
        flex-direction: column;

        h2 {
          margin-bottom: 17px;
          font-family: "CircularStd", "Roboto", sans-serif;
          font-weight: bold;
          font-style: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4em;
          font-feature-settings: "liga" off;
          color: $yellow-color;
        }
        a {
          margin: 7px 0;
          font-family: "CircularStd", "Roboto", sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 232.52%;
          font-feature-settings: "liga" off;
          color: #ffffff;
        }
      }

      .socials-mobile {
        display: none;
      }
    }

    .right {
      display: flex;
      width: 28%;
      padding: 82px 0;
      background-color: $light-navy-color;
      justify-content: center;
    }
  }

  .bottom {
    height: 89px;
    position: relative;
    display: flex;

    .border-top {
      border-top: 2px solid $light-gray-with-blue-color;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 115px);
    }

    .left {
      display: flex;
      width: calc(72% - 240px);
      background-color: $navy-color;
      padding: 26px 120px;

      span {
        font-family: "CircularStd", "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 232.52%;
        font-feature-settings: "liga" off;
        color: $white-with-gray-color;
      }
    }

    .right {
      display: flex;
      width: 28%;
      background-color: $light-navy-color;
      justify-content: center;
      align-items: center;

      .policy-terms {
        a {
          font-family: "CircularStd", "Roboto", sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 232.52%;
          text-align: right;
          font-feature-settings: "liga" off;
          color: $white-with-gray-color;
          padding: 0 21px;
        }
      }
    }
  }

  .bottom-mobile {
    display: none;
  }

  @include mobile {
    .top {
      .left {
        width: 100%;
        padding: 37px 25px;
        flex-wrap: wrap;

        .column-wrapper {
          flex: 1;
          flex-basis: 50%;

          > a {
            margin: 2px 0;
          }
        }

        .column-wrapper:nth-child(3),
        .column-wrapper:nth-child(4) {
          margin-top: 40px;
        }

        .socials-mobile {
          display: block;
          width: 160px;
          margin-top: 8px;
        }
      }
      .right {
        display: none;
      }
    }

    .bottom {
      display: none;
    }

    .bottom-mobile {
      display: flex;
      justify-content: space-between;
      padding: 10px 25px;
      background-color: $light-navy-color;

      span,
      a {
        font-family: "CircularStd", "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 232.52%;
        font-feature-settings: "liga" off;
        color: $beige-color;
      }
      a {
        margin: 0 10px;
      }
    }
  }
}
