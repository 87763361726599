@import "../../library.scss";

#stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  @media (max-width: $tablet-breakpoint) {
    margin-bottom: 10px;
  }
  .step {
    color: white;
    border: 1px solid white;
    width: 26px;
    height: 26px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 20px;
    font-weight: 300;
    margin: 0px 15px;
    @media (max-width: $tablet-breakpoint) {
      font-size: 9px;
      width: 22px;
      height: 22px;
      margin: 0px 10px;
    }
  }
  .num-container {
    position: relative;
  }
  .step-desc {
    position: absolute;
    display: inline-block;
    width: 155px;
    transform: translateX(-50%);
    left: 50%;
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    @media (max-width: $tablet-breakpoint) {
      width: 75px;
      margin-top: 5px;
      font-size: 10px;
      display: none;
    }
  }
  .line {
    content: "";
    height: 1px;
    min-width: 150px;
    background-color: white;
    display: inline-block;
    @media (max-width: $tablet-breakpoint) {
      min-width: 30px;
    }
  }

  .step.active {
    border: 1px solid $light-blue;
  }

  .line.active {
    background-color: $light-blue;
  }

  .step.completed {
    color: white;
    border: 1px solid $light-blue;
    background-color: $light-blue;
  }

  .line.completed {
    background-color: $light-blue;
  }
}
