#consultation {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 1000;

  button {
    position: absolute;
    right: 10%;
    top: 5%;
    cursor: pointer;
  }
  .calendly {
    position: relative;
    z-index: 20;
  }
}
