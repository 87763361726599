@import "../../library.scss";
@import "../../common/common.scss";

#data {
  position: relative;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    width: 50%;
    background: $light-blue-2;
    z-index: -1;
  }

  @media (max-width: 1024px) {
    &:after {
      display: none;
    }
  }

  .data-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 40px;

    h3 {
      font-weight: bold;
      font-size: 44px;
      line-height: 120%;
      color: $dark-blue-2;
      margin: 0;
      padding-top: 40px;
      margin-bottom: 20px;

      @media (max-width: 1024px) {
        padding-top: 0;
      }

      @media (max-width: 767px) {
        font-size: 32px;
        margin-bottom: 10px;
      }
    }

    .data-half {
      width: 50%;
      min-width: 300px;

      .slick-slider {
        overflow: hidden;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .data-half.left {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 15px;

      @media (max-width: 1024px) {
        padding-bottom: 25px;
      }

      .slick-track {
        height: auto !important;
      }

      .slick-slide {
        outline: none !important;
        min-height: 80px;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          min-height: 40px;
        }

        h4 {
          position: relative;
          padding-left: 66px;
          color: #cccccc;
          font-size: 26px;
          letter-spacing: -0.01em;
          @include transition;
          margin: 0;
          font-weight: 400;
          cursor: pointer;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 24px;
            padding-left: 45px;
            letter-spacing: -0.015em;
            font-size: 16px;
            font-weight: 500;
          }

          &:after {
            content: attr(data-position);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background: #cccccc;
            font-size: 20px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            @include transition;

            @media (max-width: 767px) {
              width: 29px;
              height: 29px;
              line-height: 29px;
              font-size: 18px;
            }
          }
        }

        &.slick-current {
          h4 {
            color: $light-blue-2;

            &:after {
              background: $light-blue-2;
            }
          }
        }

        div {
          outline: none !important;
        }
      }

      .data-copy-container {
        width: 85%;

        @media (max-width: 1024px) {
          width: 100%;
          max-width: 480px;
          margin: 0 auto;
        }
      }
    }

    .data-half.right {
      display: flex;
      align-items: flex-end;
      position: relative;

      @media (max-width: 767px) {
        min-height: 410px;
        overflow: hidden;
      }

      @media (max-width: 1024px) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          right: 0;
          bottom: 0;
          width: calc(100% + 30px);
          height: 100%;
          background: $light-blue-2;
          z-index: -1;
        }
      }

      p {
        opacity: 0;
      }

      .slick-slider {
        max-width: 365px;
        margin: 0 auto;
        background: #fff;
        border-radius: 35px 35px 0 0;
        padding-top: 65px;
        max-height: 600px;
        margin-top: 30px;

        @media (max-width: 1024px) {
          max-width: 356px;
          margin-top: 35px;
        }

        @media (max-width: 767px) {
          padding-top: 40px;
          max-width: 235px;
          margin-top: 0px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 24px;
        }
      }

      .slick-slide {
        // padding-top: 50px;
        min-height: 809px;

        .last-slide {
          background: #ffd463;
          min-height: 809px;
        }
      }

      img {
        margin: 0 auto;
        max-width: 100%;
      }

      .data-phone-image {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        z-index: 1;
        height: 96%;

        @media (max-width: 1024px) {
          height: 95%;
        }

        img {
          height: 100%;

          @media (max-width: $mobile-breakpoint) {
            max-width: 282px;
          }
        }
      }
    }
  }
}
