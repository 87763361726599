@import "../../library.scss";
@import "../../common/common.scss";

#schedule-call {
  background-image: url("../../../assets/schedule-call-bg.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 100px 122px 150px 122px;

  h4 {
    font-size: 32px;
    line-height: 40px;
    color: #1c315a;
  }

  @media (max-width: $tablet-breakpoint) {
    padding: 100px 52px 150px 52px;
  }

  @media (max-width: $mobile-breakpoint) {
    background-image: url("../../../assets/schedule-call-bg-mobile.svg");
    background-position: center bottom;
    background-size: contain;
    padding: 0px 30px 280px 30px;
    text-align: center;
  }

  .schedule-call-container {
    padding: 0;
    margin: 0 auto 0 0;
    max-width: 570px;
  }

  .section-header {
    text-align: left;
    padding: 0 0 26px 0;
    color: $deep-blue;

    @media (max-width: $mobile-breakpoint) {
      text-align: center;
      padding-bottom: 30px;
    }

    h4 {
      font-size: 38px;
      line-height: 52px;

      @media (max-width: $tablet-breakpoint) {
        padding: 0;
      }

      @media (max-width: $mobile-breakpoint) {
        font-size: 29px;
        line-height: 39px;
      }
    }
  }

  .schedule-call-btn {
    border-radius: 0;
    background-color: $light-blue-2;
    border-color: $light-blue-2;
    height: 53px;
    font-size: 18px;
    padding: 0px 51px;
    margin: 0;
    display: inline-block;

    @media (max-width: $mobile-breakpoint) {
      padding: 0px 41px;
    }
  }
}
