@import "../../library.scss";

.blue-button {
  font-family: "CircularStd", "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #ffffff;
  padding: 15px 20px;
  background-color: $coral;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
  border: none;
  outline: none;

  @media (max-width: 1050px) {
    font-size: 16px;
  }
}

.blue-button:hover {
  background-color: $coral-hover;
  transition: 0.2s;
}
