@import "../../library.scss";

#form-footer {
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  color: $blue-grey-1;

  img {
    width: 100px;
    margin: auto;
    @media (max-width: $tablet-breakpoint) {
      width: 80px;
    }
  }

  br {
    display: none;
  }
  p {
    text-align: center;
    font-size: 12px;
    padding: 0px 20px;
    @media (max-width: $tablet-breakpoint) {
      font-size: 10px;
    }
  }
}
