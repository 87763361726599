@import "../../library.scss";
@import "../../common/common.scss";

#value-props {
  padding: 60px 0 60px 0;
  font-family: "CircularStd", "Roboto", sans-serif;
  @media (max-width: $tablet-breakpoint) {
    padding: 30px 0 30px 0;
  }

  h2 {
    font-size: 44px;
    line-height: 120%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    font-weight: bold;
    color: $deep-blue;
    text-align: left;
    @media (max-width: $tablet-breakpoint) {
      font-size: 32px;
      line-height: 110%;
    }
  }
  h2 .with-line {
    display: inline-block;
    position: relative;
    z-index: 10;
    white-space: nowrap;
  }
  h2 .with-line:after {
    content: "";
    display: block;
    height: 27px;
    width: 203px;
    position: absolute;
    right: -4px;
    bottom: 0px;
    background-color: $yellow-1;
    z-index: -1;
    @media (max-width: $tablet-breakpoint) {
      height: 18px;
      width: 140px;
    }
    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 160%;
    font-feature-settings: "liga" off;
    color: $deep-blue;
    @media (max-width: $tablet-breakpoint) {
      font-size: 16px;
      line-height: 140%;
    }
  }
  a,
  button {
    margin-top: 20px;
    display: inline-block;
    justify-content: center;
    border: 0;
    box-shadow: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    font-feature-settings: "liga" off;
    outline: none;
    cursor: pointer;
    @media (max-width: $tablet-breakpoint) {
      font-size: 16px;
    }
  }
  button:visited,
  button:hover,
  button:active {
    border: 0;
    box-shadow: none;
  }

  .row-1:after,
  .row-2:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    clear: both;
  }
  .main-img {
    float: left;
    width: 42%;
    box-sizing: border-box;
    padding: 68px 70px 68px 0;
    background-color: $background;
    @media (max-width: $mobile-breakpoint) {
      float: none;
      width: 73%;
      padding-right: 0;
      padding-top: 27px;
      padding-bottom: 27px;
    }
  }
  .main-img img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    left: 110px;
    top: 0;
    @media (max-width: $tablet-breakpoint) {
      left: 50px;
    }
    @media (max-width: $mobile-breakpoint) {
      left: 18%;
      width: 90%;
    }
  }
  .guide-content {
    float: right;
    width: 43%;
    box-sizing: border-box;
    padding: 85px 100px 138px 0;
    font-size: 18px;
    line-height: 24px;
    @media (max-width: $tablet-breakpoint) {
      padding-right: 50px;
      padding-bottom: 50px;
    }
    @media (max-width: $mobile-breakpoint) {
      float: none;
      width: auto;
      text-align: center;
      padding: 50px 30px 30px 30px;
    }
  }
  .guide-content h2 {
    padding-bottom: 23px;
    @media (max-width: $mobile-breakpoint) {
      text-align: center;
      padding-bottom: 12px;
    }
  }
  .guide-content button {
    margin-top: 98px;
    @media (max-width: $tablet-breakpoint) {
      margin-top: 40px;
    }
    @media (max-width: $mobile-breakpoint) {
      margin-top: 30px;
    }
  }

  .get-updates {
    width: 38%;
    float: left;
    padding-left: 100px;
    padding-top: 100px;
    box-sizing: border-box;
    @media (max-width: $tablet-breakpoint) {
      padding-left: 50px;
    }
    @media (max-width: $mobile-breakpoint) {
      width: auto;
      float: none;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 53px;
      text-align: center;
    }
  }
  .get-updates h2 {
    padding-bottom: 20px;
    @media (max-width: $mobile-breakpoint) {
      padding-bottom: 13px;
      text-align: center;
    }
  }
  .get-updates h2 .with-line:after {
    width: 274px;
    @media (max-width: $tablet-breakpoint) {
      width: 200px;
    }
  }

  .statistics {
    width: 49%;
    float: right;
    box-sizing: border-box;
    padding: 60px 145px 0 25px;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    background-color: $background;
    @media (max-width: $tablet-breakpoint) {
      font-size: 27.6364px;
      line-height: 120%;
      padding-right: 60px;
      @media (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: $mobile-breakpoint) {
      width: auto;
      float: none;
      padding: 20px 15px 0 6px;
    }
  }
  .statistics h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    font-feature-settings: "liga" off;
    padding: 0 0 16px 15px;
    margin: 0;
    @media (max-width: $tablet-breakpoint) {
      font-size: 18px;
      line-height: 13px;
    }
    @media (max-width: $mobile-breakpoint) {
      padding: 0 0 10px 9px;
    }
  }
  .statistics table small {
    font-weight: bold;
    font-size: 11px;
    line-height: 160%;
    letter-spacing: 0.2em;
    font-feature-settings: "liga" off;
    display: block;
    color: $bright-blue;
    text-transform: uppercase;
    @media (max-width: $tablet-breakpoint) {
      font-size: 7.48485px;
      line-height: 160%;
    }
  }
  .statistics table td {
    border: 0;
    padding: 0 15px;
    color: $deep-blue;
    font-weight: bold;
    @media (max-width: $mobile-breakpoint) {
      padding: 0 9px;
    }
  }
  .statistics table td div {
    border-top: 1px solid $grey-3;
    padding: 24px 0 43px 0;
    @media (max-width: $mobile-breakpoint) {
      padding: 13px 0 21px 0;
    }
  }
}
