@import "src/components/library.scss";

.benefits-wrapper {
  display: flex;
  background-color: $light-blue-color;
  margin-left: 115px;
  padding-top: 80px;
  padding-bottom: 56px;

  .left {
    flex: 30;
    display: flex;

    h3 {
      font-family: "CircularStd", "Roboto", sans-serif;
      font-style: normal;
      font-size: 44px;
      line-height: 120%;
      color: $dark-blue-color;

      @media (max-width: $tablet-breakpoint) {
        font-size: 36px;
      }
    }
  }

  .right {
    flex: 70;

    .content {
      margin-right: 200px;

      div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }

      .step {
        flex: 1;

        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        max-width: 280px;
        @media (max-width: $tablet-breakpoint) {
          margin-left: 0px;
        }

        h2 {
          font-family: "CircularStd", "Roboto", sans-serif;
          font-weight: bold;
          font-style: normal;
          font-size: 44px;
          line-height: 150.02%;
          font-feature-settings: "liga" off;
          color: $royal-blue-color;
          margin: 0;
        }

        label {
          font-family: "CircularStd", "Roboto", sans-serif;
          font-weight: bold;
          font-style: normal;
          font-size: 16px;
          line-height: 150.02%;
          font-feature-settings: "liga" off;
          color: $royal-blue-color;
          margin-top: 5px;
        }

        span {
          font-family: "CircularStd", "Roboto", sans-serif;
          font-style: normal;
          font-size: 16px;
          line-height: 150.02%;
          font-feature-settings: "liga" off;
          color: #000;
          margin-top: 10px;
        }
      }
    }
  }

  @include mobile {
    flex-direction: column;
    margin-left: 50px;
    padding-top: 14px;
    padding-bottom: 40px;

    .left {
      p {
        font-size: 32px;
      }
    }

    .right {
      .content {
        margin: 0;

        div {
          justify-content: space-between;
          flex-wrap: wrap;

          .step {
            margin-bottom: 30px;
            min-width: 280px;
            width: 100%;
            label {
              font-family: "CircularStd", "Roboto", sans-serif;
              font-size: 18px;
            }

            span {
              font-family: "CircularStd", "Roboto", sans-serif;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
