@import "../../library.scss";

.MuiTextField-root {
  input {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: $navy-color;
  }

  input::placeholder {
    font-family: "CircularStd", "Roboto", sans-serif;
    color: $grey-color;
  }

  @include mobile {
    input {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
