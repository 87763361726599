@import "../../library.scss";

.form-container {
  width: 64%;
  margin: auto;

  @media (max-width: $tablet-breakpoint) {
    width: 100%;
  }
}
