@import "src/components/library.scss";

.phone-input {
  font-family: "CircularStd", "Roboto", sans-serif;
  .PhoneInputCountry {
    display: none;
  }

  input {
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $navy-color;
  }
  @include mobile {
    input {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
