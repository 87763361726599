@import "../../library.scss";

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  height: 76px;
  max-width: 1440px;
  a {
    display: flex;
  }

  @media (max-width: $tablet-breakpoint) {
    height: $navbar-height-mobile;
  }

  .logo {
    height: 35px;
    cursor: pointer;
    @media (max-width: $tablet-breakpoint) {
      width: initial;
      height: 30px;
    }
  }

  .question-phone {
    display: flex;
    align-items: center;

    .question {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: $dark-navy-color;
    margin: 0 20px;
    @media (max-width: $tablet-breakpoint) {
      margin: 0;
    }
    img {
      margin-right: 12px;
    }
  }
}

.navbar-white {
  background-color: white;
  position: fixed;
  .phone {
    color: $dark-blue;
  }
}

.navbar-transparent {
  background-color: transparent;
  position: absolute;
  .phone {
    span {
      color: white;
    }
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: 30px;
  margin-right: 35px;
  max-width: 1440px;

  @media (max-width: $tablet-breakpoint) {
    margin: 0;
  }
}
