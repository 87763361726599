@import "../../library.scss";
@import "../../common/common.scss";

#home-is-worth {
  background-color: $mint-color;

  h4 {
    color: white;
    font-size: 44px;
    line-height: 56px;
    font-weight: 700;

    @media (max-width: $tablet-breakpoint) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .home-worth-container {
    display: flex;
    justify-content: center;
    padding-bottom: 75px;

    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 50px;
    }
  }
}
