@import "../library.scss";

#social-proof-widget {
  position: fixed;
  background-color: white;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 10px;
  min-width: 260px;
  border: 2px solid #4c88f8;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 16px;
  color: #072967;
  z-index: 2;

  @media (max-width: $tablet-breakpoint) {
    width: 100%;
    bottom: 0px;
    right: initial;
    border: none;
    border-top: 2px solid #4c88f8;
    line-height: 14px;
  }
  img {
    width: 45px;
    margin-right: 15px;
    @media (max-width: $tablet-breakpoint) {
      width: 25px;
    }
  }

  .social-proof-details {
    .social-proof-entity,
    .social-proof-copy {
      @media (max-width: $tablet-breakpoint) {
        display: inline-block;
      }
    }
    span {
      font-weight: 500;
      font-size: 12px;
    }
    .social-proof-time {
      font-style: italic;
      color: #9ca9c2;
    }
  }
}

#social-proof-widget.active {
  opacity: 1;
  transition: 0.2s ease-in;
}

#social-proof-widget.closed {
  opacity: 0;
  transition: 1s ease-out;
}
