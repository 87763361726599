@import "../../library.scss";

#questions {
  background-color: $yellow-color;
  color: white;
  text-align: center;
  box-sizing: border-box;
  padding: 71px 0px 75px;

  h2 {
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: $navy-color;
    margin: 0px;
  }
  p {
    margin: 40px 0px;
    color: $navy-color;
  }
}
