@import "../library.scss";

#form {
  background-image: url("../../assets/form_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
  min-height: 650px;
  @media (max-width: $tablet-breakpoint) {
    height: 120%;
  }
}

#form-content {
  max-width: 80%;
  margin: auto;
  position: relative;
  top: 13%;
  @media (max-width: $tablet-breakpoint) {
    max-width: 90%;
    top: $navbar-height-mobile;
    padding-top: 10px;
  }
}

.form-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: white;
    margin: 30px 0px 25px;
    max-width: 900px;
    text-align: center;
    @media (max-width: $tablet-breakpoint) {
      font-size: 23px;
      text-align: center;
      margin: 10px 0px 25px;
      padding: 0px 15px;
    }
  }
}

.form-input-container {
  width: 100%;
  max-width: 724px;
  margin: auto;

  @media (max-width: $tablet-breakpoint) {
    width: 100%;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media (max-width: $tablet-breakpoint) {
    width: 90%;
  }
}

.flex-item {
  display: flex;
  align-items: center;

  .flex-3 {
    flex: 3;
    margin-right: 7.5px;
  }
  .flex-2 {
    flex: 2;
    margin-right: 7.5px;
  }
  .flex-1 {
    flex: 1;
    margin-left: 7.5px;
  }

  .address-submit {
    button {
      margin-top: 15px;
      align-self: initial;
      width: 100%;
    }
  }
}
