@import "../../library.scss";

.button {
  background: $light-blue;
  border-radius: 100px;
  border: 2px solid $light-blue;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.option {
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 600;
  background-color: white;
  color: $light-blue;
  border: 2px solid white;
  margin: 6px;
  height: 57.4px;
  width: 428px;
  display: flex;
  align-items: center;
  border-radius: 0;
  font-size: 24px;
  line-height: 30px;

  //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: $light-blue;
    transition: linear 0.2s background-color, linear 0.2s border;
    color: white;
    border: 2px solid $light-blue;
  }
  @media (max-width: $tablet-breakpoint) {
    width: 100%;
    max-width: 400px;
    height: 42.5px;
    font-size: $mobile-input-font-size;
    margin: 6px 0px;
  }
}

.button.option.active {
  border: 2px solid $light-blue;
  color: white;
  background-color: $light-blue;
}
