@import "../../library.scss";

#map-container {
  width: 600px;
  position: relative;
  border-radius: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  display: inline;
  > div > div {
    border-radius: 0;
  }

  @media (max-width: $tablet-breakpoint) {
    height: 300px;
    .marker {
      max-width: 25px;
    }
  }

  .marker {
    position: relative;
    height: 30px;
    left: -20px;
    top: -20px;
  }
}

#map-wrapper {
  height: 250px;
  @media (max-width: $tablet-breakpoint) {
    height: 150px;
  }
  @media (max-height: 700px) {
    height: 200px;
  }
}

#confirm-address {
  background-color: white;
  border-radius: 0;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #021e41;

    @media (max-width: $tablet-breakpoint) {
      font-size: 14px;
      line-height: 15px;
    }
  }

  .edit {
    color: $light-blue;
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    align-self: flex-end;

    p {
      color: $light-blue;
      font-family: "CircularStd", "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 15px;
      @media (max-width: $tablet-breakpoint) {
        font-size: 14px;
      }
    }

    img {
      margin-right: 10px;
      width: 16px;
    }
  }
}

#enter-address-suggest {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 0px 50px 50px 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  @media (max-width: $tablet-breakpoint) {
    border-radius: 0px 0px 0px 0px;
    margin: 0px 10px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    text-align: right;
    flex-wrap: wrap;
    justify-content: center;
    button {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    }
  }
  input {
    min-width: 320px;
    padding: 10px;
    font-size: $input-font-size;
    border: none;
    outline: none;
    font-weight: 300;
    @media (max-width: $tablet-breakpoint) {
      min-width: inherit;
      width: 100%;
      margin-bottom: 20px;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 20px 10px;
    }
  }
  .dropdown {
    position: absolute;
    background-color: white;
    top: 85px;
    left: 0px;
    min-width: 80%;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li:first-child {
      border-radius: 3px 3px 0px 0px;
    }

    li {
      padding: 10px 10px;
      cursor: pointer;
      transition: background-color 0.2s linear;
      text-align: left;
    }
    li:hover {
      background-color: $light-grey-2;
      transition: background-color 0.2s linear;
    }

    li:last-child:hover {
      background-color: white;
      cursor: default;
    }
    a {
      color: $light-blue;
      font-weight: 600;
    }
  }
}

#enter-address-manual {
  width: auto;

  .flex-item {
    width: 100%;
  }
}
