@import "../library.scss";
@import "../common/common.scss";

.section-header {
  text-align: center;
  padding: 75px 0px 50px;
  box-sizing: border-box;

  @media (max-width: $tablet-breakpoint) {
    padding: 50px 0px 50px;
  }

  .desktop-break {
    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  h3 {
    font-size: 12px;
    color: $light-blue;
    letter-spacing: 3px;
    margin: 0px 0px 20px 0px;
  }

  h4 {
    font-size: 36px;
    margin: 0px;
    font-weight: 600;

    @media (max-width: $tablet-breakpoint) {
      font-size: 28px;
      padding: 0px 5%;
    }
  }
}

.enter-address {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-width: 724px;
  height: 79px;
  width: 100%;

  @media (max-width: $tablet-breakpoint) {
    max-width: inherit;
    border-radius: 0px 0px 0px 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    flex-wrap: wrap;
    justify-content: center;

    button {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    }
  }

  @media (max-width: $mobile-breakpoint) {
    height: 51px;
  }

  input {
    width: 100%;
    padding: 0px;
    border: none;
    outline: none;
    font-weight: 300;
    padding-left: 62px;
    font-weight: normal;
    color: $dark-grey;
    border-radius: 0px;
  }

  .button.get-estimate-btn {
    width: 100%;
    max-width: 204px;
    height: 79px;
    border-radius: 0;
    background: $dark-blue-2;
    border: none;
    @include transition;

    &:hover {
      background: $light-blue-2;
      border: none;
    }
  }

  .dropdown {
    position: absolute;
    background-color: white;
    top: 85px;
    left: 0px;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    z-index: 100;

    @media (max-width: $tablet-breakpoint) {
      top: 55px;
      font-size: 16px;
    }

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li:first-child {
      border-radius: 3px 3px 0px 0px;
    }

    li {
      padding: 10px 10px;
      cursor: pointer;
      transition: background-color 0.2s linear;
    }

    li:hover {
      background-color: $light-grey-2;
      transition: background-color 0.2s linear;
    }

    li:last-child:hover {
      background-color: white;
      cursor: default;
    }

    a {
      color: $light-blue;
      font-weight: 600;
    }
  }
}
