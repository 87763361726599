@import "../../library.scss";

.searchInput {
  width: 100%;

  .tabs {
    margin-bottom: 17px;
    margin-left: 13px;
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 130%;

    span {
      text-align: center;
      letter-spacing: 0.2em;
      cursor: pointer;
    }

    span:nth-child(1) {
      margin-right: 26px;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  .input-wrapper > img {
    top: 31px;
    position: absolute;
    left: 24px;

    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }

  input {
    width: 100%;
    padding: 0px;
    font-size: $input-font-size;
    border: none;
    outline: none;
    font-weight: 300;
    padding-left: 62px;
    font-weight: normal;
    color: $dark-grey;
    font-size: 16px;
    height: 79px;
    display: block;

    @media (max-width: $mobile-breakpoint) {
      height: 51px;
      padding: 0;
      padding-left: 13px !important;
    }

    @include placeholder {
      font-size: 16px;
      line-height: 20px;
      color: $dark-grey;

      @media (max-width: $mobile-breakpoint) {
        font-size: 13px;
      }
    }
  }

  button {
    width: 100%;
    max-width: 204px;
    height: 79px;
    border-radius: 0;
    background: $dark-blue-2;
    border: none;
    @include transition;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    @media (max-width: $mobile-breakpoint) {
      height: 51px;
      max-width: 100px;
      font-size: 13px;
    }

    &:hover {
      background: $light-blue-2;
      border: none;
    }
  }

  .border-mint {
    border-color: $mint-color;
  }

  .border-blue {
    border-color: #4c88f8;
  }

  .selected-mint {
    color: $mint-color;
  }

  .selected-blue {
    color: #4c88f8;
  }

  .not-selected {
    color: #072967;
    opacity: 0.4;
  }

  .mint {
    background-color: $mint-color;
    color: #fff;
  }

  .mint:hover {
    background-color: $mint-color-hover;
    transition: 0.2s;
  }

  .blue {
    background-color: $royal-blue-color;
  }

  .blue:hover {
    background-color: $royal-blue-color-hover;
    transition: 0.2s;
  }

  .dark-blue {
    background-color: $dark-blue-2;
    @include transition;
    color: #fff;
  }

  .dark-blue:hover {
    background-color: $light-blue-2;
  }

  @media (max-width: $mobile-breakpoint) {
    .tabs {
      margin-left: 0;
      font-size: 11px;
    }
  }
}
