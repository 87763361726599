@import "../../library.scss";

#recent-sales {
  h2 {
    text-align: center;
    font-size: 44px;
    line-height: 120%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    font-weight: bold;
    color: #072967;
    margin: 50px 0px;
    @media (max-width: $tablet-breakpoint) {
      padding-top: 10px;
      font-size: 32px;
    }
  }
  #comps {
    display: flex;

    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }

    #comp-map {
      height: 500px;
      flex: 1;
      @media (max-width: $tablet-breakpoint) {
        flex-direction: column;
        height: 300px;
      }
    }

    .comp-pin {
      background-color: #072967;
      color: white;
      font-weight: bold;
      font-size: 16px;
      position: relative;
      padding: 5px;
      display: inline-block;
      box-shadow: 0px 2.77399px 9.70898px rgba(0, 0, 0, 0.15);
      transition: background-color 0.2s;
      &:hover {
        z-index: 100000;
      }
    }
    .selected-pin {
      background-color: #39c2aa;
      transition: background-color 0.2s;
      z-index: 100000;
    }
    .comp-pin::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 28px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #072967;
      left: 50%;
      transform: translateX(-50%);
      transition: border-top 0.2s;
    }
    .selected-pin::before {
      border-top: 10px solid #39c2aa;
      transition: border-top 0.2s;
    }

    #comp-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      list-style: none;
      height: 500px;
      flex: 1;
      padding: 0px;
      margin: 0px;
      padding: 30px 0px;
      box-sizing: border-box;
      min-height: 300px;
      position: relative;
      @media (max-width: $tablet-breakpoint) {
        flex-direction: column;
      }
      @media (max-width: $mobile-breakpoint) {
        height: inherit;
      }
      .comp-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .not-found {
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-content: center;
        @media (max-width: $mobile-breakpoint) {
          padding: 20px;
        }
        p {
          margin-bottom: 10px;
          text-align: center;
        }
      }
      .comp {
        margin: 5px 20px;
        padding: 10px;
        width: calc(50% - 62px);
        height: calc(25% - 32px);
        border-radius: 7px;
        border: 1px solid #eeeeee;
        display: flex;
        background-color: white;
        &:hover {
          border: 1px solid #39c2aa;
        }
        cursor: pointer;
        @media (max-width: $mobile-breakpoint) {
          width: calc(100% - 62px);
        }
        img {
          height: 100%;
          width: 33%;
          object-fit: cover;
          border-radius: 5px;
        }
        .details {
          width: 100%;
          padding: 2.5px 10px;
          .sale-price {
            font-size: 25px;
            font-weight: 600;
            color: #021e41;
          }
          .address {
            font-size: 14px;
            font-weight: 400;
          }
          .detail {
            font-size: 12px;
            line-height: 25px;
          }
          .detail-divider {
            width: 1px;
            background-color: #021e41;
            height: 10px;
            margin: 0px 7.5px;
            display: inline-block;
          }
        }
      }
    }
  }
}
