@import "../../library.scss";

#estimates {
  .estimate-logo {
    max-width: 200px;

    height: 75px;
    object-fit: contain;
  }
  #address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    img {
      @media (max-width: $tablet-breakpoint) {
        width: 30px;
      }
    }

    span {
      font-weight: 400;
      font-size: 20px;
      margin-top: 5px;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 80%;
    margin: auto;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border: 10px;
    box-sizing: border-box;
    padding: 30px;

    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
      max-width: 90%;
      padding: 20px 20px 30px 20px;
    }

    .estimate {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        color: $dark-blue;
        font-size: 40px;
      }

      img {
        @media (max-width: $tablet-breakpoint) {
          width: 175px;
          height: 50px;
        }
      }
    }

    .line {
      content: "";
      height: 100px;
      background-color: $light-grey-2;
      width: 2px;
      @media (max-width: $tablet-breakpoint) {
        height: 2px;
        width: 60px;
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
  }
  p {
    text-align: center;
    max-width: 60%;
    margin: auto;
    line-height: 25px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: $grey-1;
    @media (max-width: $tablet-breakpoint) {
      max-width: 80%;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.3;
    }
  }
}

.estimates {
  background-color: $brand-light-blue-color;
  .top-block-wrapper {
    background-color: $navy-color;
    padding: 40px 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: $tablet-breakpoint) {
      padding: 33px 20px 80px 20px;
    }

    h1 {
      font-weight: 700;
      font-size: 40px;
      line-height: 51px;
      text-align: center;
      color: #ffffff;
      margin: 0 0 9px;

      @media (max-width: $tablet-breakpoint) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 140.02%;
      text-align: center;
      color: #ffffff;
      margin: 0 0 30px;

      @media (max-width: $tablet-breakpoint) {
        font-size: 20px;
        line-height: 140.02%;
      }
    }

    .satellite-map {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      border: 2px solid white;
      @media (max-width: $tablet-breakpoint) {
        width: 116px;
        height: 116px;
      }

      > div > div {
        border-radius: 50%;
        border: 2px solid #ffffff;
      }
    }
  }

  .not-found-valuation-container {
    text-align: center;
    padding: 30px 20px;
    .not-found {
      padding: 50px 0px;
    }

    .blue-button {
      max-width: 320px;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 50%;
    margin: 60px auto 30px auto;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border: 10px;
    padding: 20px 60px;
    width: 100%;
    border-radius: 50px;

    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
      max-width: 90%;
      padding: 15px 30px;
      border-radius: 0px;
    }

    .estimate {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: $tablet-breakpoint) {
        margin: 18px 0;
      }

      .estimate-logo {
        max-width: 220px;
        height: 50px;
        object-fit: contain;
      }

      span {
        font-family: "CircularStd", "Roboto", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 57px;
        color: $dark-blue;

        @media (max-width: $tablet-breakpoint) {
          font-size: 40px;
          line-height: 51px;
        }
      }

      img {
        @media (max-width: $tablet-breakpoint) {
          width: 175px;
          height: 50px;
        }
      }
    }
  }

  .bottom-block-wrapper {
    background-color: $brand-light-blue-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 75px;
    text-align: center;

    @media (max-width: $tablet-breakpoint) {
      height: auto;
      margin-bottom: 50px;
    }

    h3 {
      font-size: 22px;
      color: $navy-color;
      margin: 0px;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $navy-color;
      padding: 0;
      margin-bottom: 16px;

      @media (max-width: $tablet-breakpoint) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    p {
      max-width: 684px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $light-gray-with-blue-color;

      @media (max-width: $tablet-breakpoint) {
        font-size: 18px;
        line-height: 140%;
        padding: 0 20px;
        margin-bottom: 25px;
      }
    }

    .savings {
      margin-bottom: 20px;
      .savings-amounts {
        font-size: 52px;
        color: $mint-color;
        font-weight: 800;
      }
    }

    button {
      width: 320px;
      margin-bottom: 40px;
    }

    .hero-house {
      position: absolute;
      right: 0;
      bottom: 0px;
      @media (max-width: $tablet-breakpoint) {
        position: relative;
        display: flex;
        align-self: flex-end;
        width: 60%;
      }
    }

    .second-house {
      left: 0;
      right: auto;
      @media (max-width: $tablet-breakpoint) {
        display: none;
      }
    }
  }
}
