@import "../../library.scss";

#recipient-blur {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/blur.png");
  background-color: white;
}

#recipient-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  z-index: 2;
  top: 0;
}

.sign-up-modal-wrapper {
  display: flex;
  height: 100%;

  .left {
    flex-basis: 36.5%;
    background: $yellow-color;
    background: linear-gradient(to right, $yellow-color 95%, #fff 5%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    img {
      width: 100%;
      display: block;
    }
  }

  .right {
    flex-basis: 63.5%;
    padding: 42px 31px 31px 11px;
    display: flex;
    flex-direction: column;

    .close-modal {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;

      img {
        width: 18px;
        display: block;
      }
    }

    h1 {
      font-family: "CircularStd", "Roboto", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      font-feature-settings: "liga" off;
      color: $navy-color;
      margin-bottom: 30px;
    }

    h2 {
      color: $navy-color;
    }

    .house-bg-mobile {
      display: none;
    }
  }

  @include mobile {
    .left {
      display: none;
    }

    .right {
      padding: 28px 28px 0 27px;
      flex-basis: 100%;

      h1 {
        margin-top: 30px;
      }

      .close-modal {
        right: 10px;
        top: 10px;

        img {
          width: 12px;
          display: block;
        }
      }

      .house-bg-mobile {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        bottom: 0;
        display: block;

        img {
          width: 70%;
          display: block;
        }

        .bg-mobile {
          width: 100%;
          height: 45px;
          position: absolute;
          bottom: 0;
          background-color: $yellow-color;
          z-index: -1;
        }
      }
    }
  }
}
