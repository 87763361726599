@import "../../library.scss";

#pricing-sell-wrapper, .pricing-sell-wrapper {
  padding: 90px 0 79px 225px;

  @include mobile {
    display: none;
  }

  h2 {
    font-weight: 700;
    font-style: normal;
    font-size: 40px;
    line-height: 120%;
    color: $navy-color;
    margin: 0 0 22px;
    padding: 0;
    text-align: left;
  }

  .advantage-table {
    .row {
      height: 72px;
      display: flex;
      border-bottom: 3px solid $white-with-blue-color;

      .table-header {
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2em;
        font-feature-settings: "liga" off;
        color: #000000;
      }

      .name-column {
        flex: 50;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        line-height: 400%;
        font-feature-settings: "liga" off;
        color: $navy-color;
      }

      .felix-column,
      .traditional-column,
      .empty {
        flex: 15;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .empty {
        flex: 20;
      }
    }
  }
}

#pricing-sell-wrapper-mobile {
  display: none;
  padding-top: 54px;
  padding-left: 0;
  padding-bottom: 0;

  @include mobile {
    display: block;
  }

  .content-wrapper {
    padding-left: 53px;
  }

  img {
    display: block;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.2em;
    font-feature-settings: "liga" off;
    color: $navy-color;
    margin: 0 0 12px;
  }

  h2 {
    font-size: 32px;
    line-height: 110%;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    max-width: 300px;
  }
  .advantage-table {
    .row {
      .name-column {
        line-height: inherit;
      }
    }
  }
}
