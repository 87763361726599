@import "../../library.scss";

#property-type-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .property-type-row {
    display: flex;
  }
}
