@import "../library.scss";

#phone-number {
  color: white;
}

.option-btn {
  padding: 10px;
  min-width: 200px;
  margin: 5px 0px;
  cursor: pointer;
}

.option-btn.active {
  background-color: $light-blue;
  color: white;
}

#phone-form-control,
.form-input {
  padding: 15px 20px;
  border-radius: 0;
  font-size: $input-font-size;
  outline: none;
  border: none;
  width: 100%;
  font-weight: 300;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  @media (max-width: $tablet-breakpoint) {
    padding: 12.5px 20px;
    font-size: $mobile-input-font-size;
  }
}

.PhoneInputCountry {
  display: none;
}

.form-input-control {
  display: flex;
  flex-direction: column;
  @media (max-width: $tablet-breakpoint) {
    width: 100%;
  }
  label {
    font-family: "CircularStd", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    font-feature-settings: "liga" off;
    color: #ffffff;
    margin-bottom: 5px;

    @media (max-width: $tablet-breakpoint) {
      line-height: 25px;
      font-size: 14px;
    }
  }
  .form-error {
    font-size: 12px;
    height: 17px;
    display: flex;
    align-items: flex-end;
    color: $error-red;
    justify-content: center;
    font-weight: 600;
    @media (max-width: $tablet-breakpoint) {
      font-size: 10px;
      height: 12px;
      height: 20px;
    }
  }
}

.form-text-field {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 10px 20px;
  outline: none;
  display: inherit;
  width: 95%;
  height: 200px;
  margin: auto;
  margin-top: 10px;
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: $dark-blue;

  @media (max-width: $tablet-breakpoint) {
    height: 150px;
  }
}

.button {
  background: $light-blue;
  border-radius: 100px;
  border: 2px solid $light-blue;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.next {
  font-weight: 600;
  height: 52.5px;
  float: right;
  margin-top: 20px;
  display: flex;
  align-items: center;
  @media (max-width: $tablet-breakpoint) {
    bottom: 100px;
    height: 45px;
    font-size: 14px;
  }
}

.button.outlined {
  background-color: white;
  color: $light-blue;
}

.button.back {
  background-color: transparent;
  border: 2px solid transparent;
  font-weight: 600;
  position: absolute;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    left: -50px;
    top: -10px;
  }

  @media (max-width: $tablet-breakpoint) {
    position: relative;
    font-size: 14px;
    min-width: 50px;
    padding: 0;
    border: none;
    left: 0;
  }

  img {
    margin-right: 5px;
  }
}

.button.action {
  height: 52.5px;
  font-weight: 600;
  padding: 0px 40px;
}

.button.action.recipient-submit {
  margin-top: 10px;
  align-self: flex-end;

  @media (max-width: $tablet-breakpoint) {
    height: 45px;
  }
}

.button.get-estimate-btn {
  font-size: 16px;
  height: 54px;
  padding: 0px 23px;
  background-color: $grass-green;
  border: $grass-green 2px solid;
  display: flex;
  align-items: center;

  @media (max-width: $tablet-breakpoint) {
    height: 50px;
    font-size: $mobile-input-font-size;
    min-width: 200px;
  }
  &:hover {
    border: #059c69 2px solid;
    transition: background-color 0.3s ease-in, border 0.15s ease-in;
    background-color: #059c69;
  }
}

.button.follow-up {
  height: 50px;
  padding: 0px 30px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  .css-gkujxd {
    background-color: #3b9cff;
  }
  .css-1255re {
    background-color: #ffc024;
  }
  .css-k1tjab {
    background-color: #ff4d63;
  }
  .css-1byut91 {
    background-color: #06b57a;
  }
  .css-kn5t7r {
    background-color: #021e41;
  }
}
