@import "./components/library.scss";

html {
  height: 100%;
}

body {
  margin: 0 auto;
  max-width: 1440px;
  font-family: "CircularStd", "Roboto", sans-serif;
  margin: 0 auto;
  max-width: 1440px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: none;
  height: 100%;
}

@font-face {
  font-family: "CircularStd";
  font-weight: 400;
  src: local("CircularStd"),
    url("./fonts/CircularStd/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd";
  font-weight: 500;
  src: local("CircularStd"),
    url("./fonts/CircularStd/CircularStd-Medium.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd";
  font-weight: 600;
  src: local("CircularStd"),
    url("./fonts/CircularStd/CircularStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd";
  font-weight: 900;
  src: local("CircularStd"),
    url("./fonts/CircularStd/CircularStd-Black.otf") format("opentype");
}

textarea {
  resize: none;
}

a {
  text-decoration: none;
}

input,
button {
  font-family: "CircularStd", "Roboto", sans-serif;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 0px 15px 0px;
}

p {
  line-height: 28px;
  font-size: 18px;
  margin: 0px;
  @media (max-width: $tablet-breakpoint) {
    line-height: 25px;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.hide-on-mobile {
  @media (max-width: $tablet-breakpoint) {
    display: none;
  }
}

.hide-on-desktop {
  @media (min-width: $tablet-breakpoint) {
    display: none;
  }
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0px 15px;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 300;
  color: $grey-1;
  @media (max-width: $tablet-breakpoint) {
    font-size: $mobile-input-font-size;
  }
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 300;
  color: $grey-1;
  @media (max-width: $tablet-breakpoint) {
    font-size: $mobile-input-font-size;
  }
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 300;
  color: $grey-1;
  @media (max-width: $tablet-breakpoint) {
    font-size: $mobile-input-font-size;
  }
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "CircularStd", "Roboto", sans-serif;
  font-weight: 300;
  color: $grey-1;
  @media (max-width: $tablet-breakpoint) {
    font-size: $mobile-input-font-size;
  }
}
