$light-blue-2: #4c88f8;
$light-blue: #3b9cff;
$baby-blue: #f2f5fc;
$bright-blue: #568ff8;
$dark-blue: #021e41;
$deep-blue: #072967;

$dark-grey: #495057;
$dark-blue-2: #072967;
$light-blue-2: #4c88f8;

$mint-color: #39c2aa;
$mint-color-hover: #33ae99;
$royal-blue-color: #4c88f8;
$royal-blue-color-hover: #3378f7;
$light-blue-color: #f2f6fe;
$dark-blue-color: #1c315a;
$dark-grey: #495057;
$dark-blue-2: #072967;
$light-blue-2: #4c88f8;
$light-blue-color: #f2f6fe;
$mint-color: #39c2aa;
$mint-color-hover: #33ae99;
$royal-blue-color-hover: #3378f7;
$light-gray-with-blue-color: #526a95;
$white-with-gray-color: #e9ecef;
$light-grey-1: #f7f7f7;
$light-grey-2: #e8e8e8;
$light-grey-3: #e9ecef;
$light-grey-4: #f0f0f0;
$beige-color: #f9f4f2;
$yellow-color-bg: #ffeab9;
$dark-navy-color: #021e41;
$blue-grey-1: #9aa5b3;

$grey-1: #757575;
$grey-2: #67788d;
$grey-3: #cdd4e1;

$grass-green: #06b57a;

$error-red: #ff5a5a;

$navbar-height: 65px;

$navbar-height-mobile: 60px;

$background: #f6f8fd;

$yellow-1: #ffeab9;

$mint-color: #39c2aa;
$mint-color-hover: #33ae99;

$navy-color: #072967;
$light-navy-color: #093179;

$yellow-color: #ffd463;
$brand-light-blue-color: #f2f6fe;

$royal-blue-color: #4c88f8;
$royal-blue-color-hover: #3378f7;

$coral: #f25e6a;
$coral-hover: #f04d5b;

$white-with-blue-color: #f2f4f7;
$grey-color: #8394b3;

// Font Sizes
$nav-btn-font-size: 20px;

$input-font-size: 16px;

$mobile-input-font-size: 16px;

// Break Points
$tablet-breakpoint: 769px;
$mobile-breakpoint: 414px;

// mobile device
@mixin mobile {
  @media (max-width: #{$tablet-breakpoint}) {
    @content;
  }
}

//custom mixins
@mixin coverdiv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

@mixin transition($prop: all, $dur: 0.3s, $ease: ease) {
  -webkit-transition: $prop $dur $ease;
  -moz-transition: $prop $dur $ease;
  transition: $prop $dur $ease;
}
