@import "../../../library.scss";

.property-type {
  min-width: 200px;
  min-height: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;
  cursor: pointer;
  flex-direction: column;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  color: $navy-color;
  position: relative;

  @media (max-width: $tablet-breakpoint) {
    min-width: 110px;
    min-height: 110px;
    margin: 5px;
  }
  img {
    width: 90px;
    height: 90px;

    @media (max-width: $tablet-breakpoint) {
      width: 40px;
      height: 40px;
    }
  }

  h4 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    position: absolute;
    bottom: 20px;

    @media (max-width: $tablet-breakpoint) {
      font-size: 14px;
    }
  }
}
.property-type.active {
  background-color: $royal-blue-color;
  color: white;
}
